<template>
  <div class="question-dropdown" :class="{ 'slide-open': showDropdown }" >
    <div class="dropdown-header">SUGGESTED QUESTIONS</div>
    <ul class="dropdown-list">
      <li
        v-for="(question, index) in questions"
        :key="index"
        :class="{ highlighted: index === highlightedIndex }"
        @mousedown="selectQuestion(question)"
        @mouseover="onMouseOver(index)"
      >
        {{ question.text }}
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: 'QuestionDropdown',
  props: {
    questions: {
      type: Array,
      required: true,
    },
    highlightedIndex: {
      type: Number,
      default: -1,
    },
    showDropdown: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    selectQuestion(question) {
      this.$emit('select-question', question);
    },
    onMouseOver(index) {
      this.$emit('update-highlighted-index', index);
    },
  },
};
</script>

<style scoped>
.question-dropdown {
  background: var(--colors-neutral-gray-50, #1F291F);
  width: 100%;
  position: absolute;
  z-index: 1000;
  max-height: 0;
  overflow-y: auto;
  border-radius: 8px;
  margin-bottom: 4px; /* Adjusted for positioning above */
  bottom: calc(100% + 4px); /* Position above the input field with a small gap */
  left: 0;
  transition: max-height 0.3s ease-out, visibility 0.3s;
  visibility: hidden;
}

.question-dropdown.slide-open {
  max-height: 200px; /* Full height when open */
  visibility: visible;
}

.dropdown-header {
  color: var(--colors-neutral-gray-400, #929691);
  text-align: left;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: 16px; /* 133.333% */
  padding: 16px 16px;
}

.dropdown-list {
  list-style: none;
  margin: 0;
  padding: 0;
}

.dropdown-list li {
  padding: 8px 16px;
  color: var(--colors-neutral-gray-600, #DBDCDA);
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
  cursor: pointer;
}

.dropdown-list li.highlighted,
.dropdown-list li:hover {
  background-color: #353E34; /* Slight highlight color */
}
</style>
