<template>
  <div id="app">
    <LeftNavBar 
      class="left-nav"
      :sessions="sessions"
      :currentSession="currentSession"
      :username="userId"
      :auth0="auth0"
      :email="userEmail"
      @update:currentSession="setCurrentSession"
      @update:selectedConfig="setSelectedConfig"
      @session-deleted="handleSessionDeleted"
      @session-renamed="handleSessionRenamed"
    />
    <div class="main-container">
      <div v-if="currentSession" class="active-investigation">
        <!-- Active Investigation Content -->
        <div class="investigation-content">
          <div class="chat-container">
            <div class="chat-messages">
              <ChatMessage 
                v-for="message in chatMessages" 
                :key="message.id" 
                :type="message.type" 
                :message="message.text" 
                class="message-component"
                :messageId="message.id"
                :avatarSrc="message.type === 'system' ? require('@/assets/avatar_logo.svg') : require('@/assets/default_profile.svg')"
                :artifacts="message.artifacts"
                :agentLogs="message.agentLogs"
                :isStreaming="message.isStreaming"  
                @submit-message-feedback="submitMessageFeedback"
              />
              <!-- <LoadingSpinner :isLoading="isStreamingChat" /> -->
            </div>
          </div>
        </div>
        <!-- Floating Input Field and Controls -->
        <div class="floating-input-container">
          <div class="landing-toggle-submit-recommendation">
            <div class="toggle-container">
              <!-- 
              Uncomment to deploy beyond Dripshop
              <ToggleSwitch :value="toggleValue" @input="toggleValue = $event" />
              <span>Auto-investigate</span>
              -->
            </div> 
            <div class="manual-question-inputs">
              <InputField
                v-if="toggleValue == false"
                class="question-input"
                label="ManualQuestion"
                placeholder="Manually enter a question to investigate"
                :value="inputValue"
                @inputField="updateInputValue"
                @enter="onEnterKey"
                :questions="questions"
                :agentTypeValue="inputAgentType"
                @update:agentTypeValue="updateAgentType"
                :agentTypeOptions="agentTypeOptions"
                :submitDisabled="isSubmitButtonDisabled"
                @submit="startInvestigation"
              />
            </div>
          </div>
        </div>
      </div>
      <div v-else class="landing-page">
        <img src="@/assets/full_logo.svg" alt="Full Logo" class="landing-logo" />
        <p class="landing-text">Untangle system failures the easy way.</p>
        <p class="landing-text-2">Prototype Warning: Limited tools (Datadog logs); errors are expected.</p>
        <InputField 
          label="Investigation" 
          placeholder="What issues would you like to investigate from your system?" 
          :value="inputValue" 
          @inputField="updateInputValue" 
          @enter="onEnterKeyLanding"
          :questions="questions"
          :agentTypeValue="inputAgentType"
          @update:agentTypeValue="updateAgentType"
          :agentTypeOptions="agentTypeOptions"
          :submitDisabled="isLandingPageSubmitDisabled"
          @submit="createSession"
        />
        <div class="landing-toggle-submit">
          <div class="toggle-container">
            <!-- 
            Uncomment to deploy beyond Dripshop
            <ToggleSwitch :value="toggleValue" @input="toggleValue = $event" />
            <span>Auto-investigate</span>
            -->
          </div>
          <!-- <PrimaryButton type="primary" icon="arrow" @click="createSession" :disabled="isLandingPageSubmitDisabled">Submit</PrimaryButton> -->
        </div>
        <LoadingSpinner :isLoading="isCreatingSession" />
      </div>
    </div>
  </div>
</template>


<script>
import LeftNavBar from './components/LeftNavBar.vue';
// import TopNavBar from './components/TopNavBar.vue';
// import PrimaryButton from './components/Buttons/PrimaryButton.vue';
// import ToggleSwitch from './components/Buttons/ToggleSwitch.vue';
// import MiniButton from './components/Buttons/MiniButton.vue';
import InputField from './components/InputField.vue';
import ChatMessage from './components/ChatMessage.vue';
// import QuestionList from './components/QuestionList.vue';
// import AgentView from './components/AgentView.vue';
import LoadingSpinner from './components/LoadSpinner.vue';
import { useAuth0 } from '@auth0/auth0-vue';

export default {
  name: 'App',
  components: {
    LeftNavBar,
    // TopNavBar, // Removed TopNavBar
    // PrimaryButton,
    // ToggleSwitch,
    // MiniButton, // Removed MiniButton
    InputField,
    ChatMessage,
    // QuestionList,
    // AgentView,
    LoadingSpinner,
  },
  data() {
    return {
      // existing data properties
      currentSession: '',
      inputValue: '',
      inputAgentType: 'log_agent', // Default agent type
      toggleValue: false,
      chatMessages: [],
      agentLogs: [],
      questions: [],
      sessions: [],
      isCreatingSession: false,
      isLoadingQuestions: false,
      isStreamingChat: false,
      waitingOnAuth: false,
      userId: '',
      userEmail: '',
      url: 'https://api.traversal.com', //'https://traversal-agent.traversal.svc.meow.nyc2.internal.digitalocean.com', // 'https://api.traversal.com',
      isCollapsed: true, // Added isCollapsed property
      selectedConfig: '', // Add this line
      agentTypeOptions: [{ value: 'log_agent', label: 'Search Logs' }],

    };
  },
  mounted() {
    // existing code
    const auth0Context = useAuth0();
    this.auth0 = auth0Context;

    const { isAuthenticated, loginWithRedirect, user, isLoading } = auth0Context;

    // Function to check the authentication status after isLoading completes
    const checkAuth = () => {
      if (!isLoading.value) {
        if (!isAuthenticated.value) {
          console.log("User is not authenticated, sending to Auth0 to login");
          this.waitingOnAuth = true;
          loginWithRedirect();
        } else if (isAuthenticated.value) {
          console.log("User is authenticated the user id sub is :", user.value.sub);
          this.userId = user.value.sub; 
          this.userEmail = user.value.email;
          this.waitingOnAuth = false;
          this.fetchSessions();
        }
      }
    };

    // Watch for changes to isLoading and check auth status once it's done loading
    let interval = setInterval(() => {
      if (!isLoading.value) {
        checkAuth();
        clearInterval(interval); // Clear the interval once the check is done
      }
    }, 100);

    // Stop checking after 15 seconds to avoid infinite loop
    setTimeout(() => clearInterval(interval), 15000);
  },
  computed: {
    isSubmitButtonDisabled() {
      return (!this.inputValue && !this.questions.some(question => question.selected) && !this.toggleValue) || this.isStreamingChat;
    },
    isLandingPageSubmitDisabled() {
      return !this.inputValue || this.isCreatingSession;
    }
  },
  methods: {
    toggleCollapse() {
      this.isCollapsed = !this.isCollapsed;
    },
    updateAgentType(value) {
      this.inputAgentType = value;
    },
    setSelectedConfig(config) {
      this.selectedConfig = config;
    },
    updateInputValue(event) {
      this.inputValue = event.target.value;
    },
    onEnterKey() {
      if (!this.isSubmitButtonDisabled) {
        this.startInvestigation();
      }
    },
    onEnterKeyLanding() {
      if (!this.isLandingPageSubmitDisabled) {
        this.createSession();
      }
    },
    updateQuestions(updatedQuestions) {
      this.questions = updatedQuestions;
    },
    handleSessionDeleted(sessionId) {
      if (this.currentSession === sessionId) {
        this.resetInvestigation();
      }
      this.fetchSessions();
    },
    handleSessionRenamed() {
      this.fetchSessions();
    },
    async submitMessageFeedback(chatId, feedbackMessage) {
      try {
        const { getAccessTokenSilently } = this.auth0;  // Now we use the stored context
        const token = await getAccessTokenSilently();

        const response = await fetch(`${this.url}/save_message_feedback`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
          },
          body: JSON.stringify({
            session_id: this.currentSession,
            chat_id: chatId,
            feedback_message: feedbackMessage,
          }),
        });
        const data = await response.json();
        if (data.status === 'success') {
          console.log('Message feedback saved successfully.');
        } else {
          console.error('Failed to save message feedback:', data.message);
        }
      } catch (error) {
        console.error('Error in submitMessageFeedback:', error);
      }
    },
    async submitQuestionFeedbackFull(chatId, feedbackMessage, content) {
      try {
        const { getAccessTokenSilently } = this.auth0;  // Now we use the stored context
        const token = await getAccessTokenSilently();

        const response = await fetch(`${this.url}/save_question_feedback`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
          },
          body: JSON.stringify({
            session_id: this.currentSession,
            chat_id: chatId,
            feedback_message: feedbackMessage,
            content: content,
          }),
        });
        const data = await response.json();
        if (data.status === 'success') {
          console.log('Question feedback saved successfully.');
        } else {
          console.error('Failed to save question feedback:', data.message);
        }
      } catch (error) {
        console.error('Error in submitQuestionFeedback:', error);
      }
    },
    submitQuestionFeedback(feedbackMessage, content) {
      const lastChatMessage = this.chatMessages[this.chatMessages.length - 1];
      if (lastChatMessage) {
        this.submitQuestionFeedbackFull(lastChatMessage.id, feedbackMessage, content);
      }
    },
    // end of feedback functions
    async fetchSessions() {
      console.log("Fetching sessions for user id:", this.userId);

      const { getAccessTokenSilently } = this.auth0;  // Now we use the stored context
      const token = await getAccessTokenSilently();

      try {
        console.log("Fetching sessions for user id:", this.userId);
        const response = await fetch(`${this.url}/get_all_sessions?user_id=${this.userId}`, {
          headers: {
            'Authorization': `Bearer ${token}`,
          }
        });
        const data = await response.json();
        console.log("Response from get_all_sessions:", response);
        this.sessions = data.all_sessions;
      } catch (error) {
        console.error("Error fetching sessions:", error);
      }
    },
    async setCurrentSession(session) {
      this.isStreamingChat = true;
      this.isLoadingQuestions = true;

      if (session) {
        this.fetchSessions();
        this.resetInvestigation();
        this.currentSession = session;

        // pull and render chat messages from /get_session which takes session_id and returns chat_id, content, and type for messages in order as a json of list of dictionaries
        try {
          const { getAccessTokenSilently } = this.auth0;  // Now we use the stored context
          const token = await getAccessTokenSilently();
          const response = await fetch(`${this.url}/get_session?session_id=${session}&user_id=${this.userId}`, {
            headers: {
              'Authorization': `Bearer ${token}`,
            },
          });
          const data = await response.json();
          let last_question = ""
          this.chatMessages = [];

          for (let message of data) {
            if (message.message_type === 'user') {
              last_question = message.content;
              this.chatMessages.push({
                id: message.chat_id,
                type: message.message_type,
                text: message.content,
              });
            } else {
              this.agentLogs.push({
                id: message.chat_id,
                text: "## " + last_question + " | Tasks\n" + message.content,
              });

              // Process artifacts using the new method
              const artifacts = await this.processArtifacts(message.artifacts);

              this.chatMessages.push({
                id: message.chat_id,
                type: message.message_type,
                text: message.parsed_output,
                artifacts: artifacts,
                agentLogs: message.content,
                isStreaming: false,
              });
            
            }
          }
        } catch (error) {
          console.error("Error fetching chat messages:", error);
        }

        this.isStreamingChat = false;
        this.fetchRecommendedQuestions();
      } else {
        this.fetchSessions();
        this.resetInvestigation();
      }
    },
    async resetInvestigation() {
      this.inputValue = '';
      this.chatMessages = [];
      this.agentLogs = [];
      this.questions = [];
      this.currentSession = '';
    },
    // Create new session from landing page
    async createSession() {
      this.isCreatingSession = true;
      this.isLoadingQuestions = true;
      this.isStreamingChat = true;

      this.chatMessages = [];
      this.agentLogs = [];
      this.questions = [];
      this.currentSession = '';

      console.log("Creating session from landing page with input:", this.inputValue);
      
      try {
        const { getAccessTokenSilently } = this.auth0;  // Now we use the stored context
        const token = await getAccessTokenSilently();

        const response = await fetch(`${this.url}/create_session`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
          },
          body: JSON.stringify({
            user_id: this.userId,
            user_message: this.inputValue,
            user_email: this.userEmail,
            config_name: this.selectedConfig,
          }),
        });

        if (response.ok) {
          const data = await response.json();
          this.currentSession = data.session_id;
          // Add the user message to the chatMessages - we're no longer doing this but uncomment if needed
          // this.chatMessages.push({
          //   id: data.chat_id,
          //   type: 'user',
          //   text: data.content,
          // });
          console.log("Finished creating session");
        } else {
          console.error("Failed to start the session:", response.statusText);
        }
      } catch (error) {
        console.error("Error in startSession:", error);
      }
      this.isCreatingSession = false;
      this.isStreamingChat = false;
      this.startInvestigation();
    },
    // Artifact
    async fetchArtifact(artifactData) {
      try {
        console.log("Fetching artifact:", artifactData);
        const { getAccessTokenSilently } = this.auth0;
        const token = await getAccessTokenSilently();
        const response = await fetch(`${this.url}/get_artifact?session_id=${artifactData.session_id}&chat_id=${artifactData.chat_id}&tool_call_id=${artifactData.tool_call_id}`, {

          headers: {
            'Authorization': `Bearer ${token}`,
          },
        });
        if (response.ok) {
          const artifact = await response.json();
          // Process the artifact
          return artifact;
        } else {
          console.error("Failed to fetch artifact:", response.statusText);
          return null;
        }
      } catch (error) {
        console.error("Error fetching artifact:", error);
        return null;
      }
    },
    async processArtifacts(artifactsDataArray) {
      const processedArtifacts = [];

      for (let artifactData of artifactsDataArray) {
        let retrievedArtifact = await this.fetchArtifact(artifactData);

        console.log("Metric in processed artifact: ", retrievedArtifact.metrics?.metric_queries);

        if (retrievedArtifact) {
          processedArtifacts.push({
            images: retrievedArtifact.images || [],
            urls: retrievedArtifact.urls || [],
            texts: retrievedArtifact.texts || [],
            dataframes: retrievedArtifact.dataframes || [],
            jsons: retrievedArtifact.jsons || [],
            logs: retrievedArtifact.logs?.logs || [],
            metrics: retrievedArtifact.metrics?.metric_queries || [],
            tool_name: artifactData.tool_name,
            tool_args: artifactData.tool_args,
            tool_call_id: artifactData.tool_call_id,
          });
        }
      }

      console.log("Processed artifacts:", processedArtifacts);

      return processedArtifacts;
    },
    // Start investigation once you're in an active session
    async startInvestigation() {
      
      this.isStreamingChat = true;
      if (this.toggleValue) {
        this.inputValue = '';
      }

      let selectedQuestionsText = '';
      let selectedQuestionType = '';
      if (this.inputValue) {
        selectedQuestionsText = this.inputValue;
        selectedQuestionType = this.inputAgentType; // TODO REPLACE WITH ENDPOINT FOR INFERRING AGENT TYPE
      } else {
        if (this.toggleValue) {
          this.questions[0].selected = true;
        }

        const selectedQuestions = this.questions.filter(question => question.selected);
        selectedQuestionsText = selectedQuestions.map(question => question.text).join(' ');
        selectedQuestionType = selectedQuestions.map(question => question.agent_type)[0];
      }
      this.inputValue = '';

      if (selectedQuestionType === 'FINAL') {
        console.log("Final question selected, ending investigation");
        this.isLoadingQuestions = false;
        this.isStreamingChat = false;
        // Eventually save this as a conclusion to our backend. 
        return;
      }

      console.log("Selected questions in investigation:", selectedQuestionsText);

      // send it to /setup_stream_chat
      let systemMessageId = 'tmp';
      try {
        const { getAccessTokenSilently } = this.auth0;  // Now we use the stored context
        const token = await getAccessTokenSilently();

        const response = await fetch(`${this.url}/setup_stream_chat`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
          },
          body: JSON.stringify({
            user_message: selectedQuestionsText,
            user_id: this.userId,
            session_id: this.currentSession,
            agent_type: selectedQuestionType,
            ranked_questions: this.questions,
            user_email: this.userEmail,
            config_name: this.selectedConfig,
          }),
        });
        if (response.ok) {
          const data = await response.json();
          console.log("Returned data from setup_stream_chat");
          this.chatMessages.push({
            id: data.user_chat_id,
            type: 'user',
            text: data.question,
          });
          systemMessageId = data.system_chat_id;
          this.chatMessages.push({
            id: systemMessageId,
            type: 'system',
            text: '',
          });
        } else {
          console.error("Failed to start the session:", response.statusText);
        }
      } catch (error) {
        console.error("Error in startInvestigation:", error);
      }

      // send it to /stream_chat
      try {
        const { getAccessTokenSilently } = this.auth0;  // Now we use the stored context
        const token = await getAccessTokenSilently();

        const response = await fetch(`${this.url}/stream_chat`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
          },
          body: JSON.stringify({
            user_message: selectedQuestionsText,
            user_id: this.userId,
            session_id: this.currentSession,
            agent_type : selectedQuestionType,
            chat_id: systemMessageId,
            user_email: this.userEmail,
            config_name: this.selectedConfig,
          }),
        });

        if (response.ok) {
          const reader = response.body.getReader();
          const decoder = new TextDecoder('utf-8');
          let isStream = false;
          let isConclusion = false;
          let isArtifact = false;
          let isEndOfChat = false;

          let systemMessage = this.chatMessages.find(msg => msg.id === systemMessageId);
          systemMessage.isStreaming = true;

          this.agentLogs.push({
            id: systemMessageId,
            text: "## " + selectedQuestionsText + " | Tasks\n",
          });
          systemMessage.agentLogs = "";

          // Main text stream processing loop
          while (!isEndOfChat) {
            const { value, done: doneReading } = await reader.read();
            isEndOfChat = doneReading;
            let curr_chunk = decoder.decode(value, { stream: !isEndOfChat });

            if (curr_chunk) {
              let chunk_sections = curr_chunk.split("[STREAM_SECTION]");
                for (let chunk of chunk_sections) {
                  if (chunk.includes("[STREAM]")) {
                    isStream = !isStream;
                    chunk = chunk.replace("[STREAM]", "");
                  }
                  if (chunk.includes("[CONCLUSION]")) {
                    isConclusion = !isConclusion;
                    chunk = chunk.replace("[CONCLUSION]", "");
                  }
                  if (chunk.includes("[ARTIFACT]")) {
                    isArtifact = !isArtifact;
                    chunk = chunk.replace("[ARTIFACT]", "");
                  }
                  if (chunk.includes("[END_OF_CHAT]")) {
                    isEndOfChat = true;
                    chunk = chunk.replace("[END_OF_CHAT]", "");
                  }

                  if (isArtifact) {
                    let data = null;
                    try {
                      if (chunk.length > 20) {
                        console.log("Chunk:", chunk);
                      }
                      data = JSON.parse(chunk);
                    } catch (error) {
                      data = null;
                    }
                    if (data && data.tool_message_output_type === 'artifact') {
                      console.log("Received artifact:", data);
                      if (!systemMessage.artifacts) {
                        systemMessage.artifacts = [];
                      }

                      let processedArtifacts = await this.processArtifacts([data]);
                      systemMessage.artifacts.push(processedArtifacts[0]);

                      //systemMessage.artifacts.push(data);
                      //this.fetchAndProcessArtifact(data, systemMessage);
                    }
                    continue;
                  }
                  if (isStream) {
                    // Stream to agentLogs
                    this.agentLogs[this.agentLogs.length - 1].text += chunk;
                    systemMessage.agentLogs += chunk;
                  }
                  if (isConclusion) {
                    // Append remaining content to system message
                    systemMessage.text += chunk;
                  }              
                }
            }
          }

          // Chunk done streaming - parse systemMessage JSON and replcae with right field
          if (systemMessage.text) {
            try {
              const systemMessageContent = JSON.parse(systemMessage.text);
              const fullText = systemMessageContent.result;
              const pieces = fullText.split('');

              systemMessage.text = '';
              // Add text in pieces over the calculated interval
              pieces.forEach((char, index) => {
                setTimeout(() => {
                  systemMessage.text += char;
                }, index * 2000 / pieces.length);
              });
            } catch (e) {
              // If parsing fails, keep the text as is
              console.warn("Could not parse system message text as JSON:", e);
            }
          }
          // TODO: Consider if we want to keep isStreaming on the message level to true after
          systemMessage.isStreaming = false;

        } else {
          console.error("Failed to start the session:", response.statusText);
        }
      } catch (error) {
        console.error("Error in startInvestigation:", error);
      }
      this.isStreamingChat = false;
      this.isLoadingQuestions = true;
      this.fetchRecommendedQuestions();
    },
    fetchRecommendedQuestions() {
      //get index of selected question
      let selectedQuestionIndex = this.questions.findIndex(question => question.selected);
      this.inputValue = '';
      this.questions = [];
      this.isLoadingQuestions = true;
      // make get request to /get_questions, sending it session_id and it should return JSON with questions (as a list of strings) and agent_types (as a list of strings). Both should be saved, and given ids before displayed in questionlist
      this.fetchSessions();
      try {
        //get requestion with session_id and user_id
        const { getAccessTokenSilently } = this.auth0;  // Now we use the stored context

        getAccessTokenSilently().then(token => {

          fetch(`${this.url}/get_questions?session_id=${this.currentSession}&user_id=${this.userId}&user_email=${this.userEmail}&config_name=${this.selectedConfig}`, {
            headers: {
              'Authorization': `Bearer ${token}`,
            }
          })
            .then(response => response.json())
            .then(data => {
              this.questions = data.questions
                .map((question, index) => {
                  let agentType = data.agent_types[index];
                  if (agentType.includes('FINISH')) {
                    return null;
                  }
                  return {
                    id: index,
                    text: question,
                    agent_type: agentType,
                    selected: selectedQuestionIndex === index,
                  };
                })
                .filter(question => question !== null); // remove nulls from the list

              this.isLoadingQuestions = false;
              // If auto-investigate is on, select the first question and then start the investigation
              if (this.toggleValue) {
                this.questions[0].selected = true;
                // wait for the next tick to ensure the selected question is rendered
                this.$nextTick(() => { 
                  // this.startInvestigation();
                  this.$nextTick(() => {
                    this.startInvestigation();
                  });
                });
                //this.startInvestigation();
              }
            })
            .catch(error => {
              console.error("Error fetching recommended questions:", error);
            });
        });
      } catch (error) {
        console.error("Error fetching recommended questions:", error);
      }
    },
  },
};
</script>



<style scoped>
/* General Styles */
body, footer, html {
  padding: 0;
  margin: 0;
  width: 100%;
  height: 100%;
}

#app {
  font-family: Inter, Helvetica, Arial, sans-serif;
  color: #ffffff;
  background-color: #0C160B;
  display: flex;
  padding: 0px;
  margin: 0;
  width: 100vw;
  max-width: 100vw;
  height: 100vh;
}

.main-container {
  width: 100%;
  height: 100%;
  position: relative; /* Needed for positioning floating elements */
}

/* Landing Page Styles */
.landing-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex: 1;
  padding: 40px 0;
  margin-top: 200px;
  height: auto;
}

.landing-logo {
  display: flex;
  width: 320.101px;
  height: var(--spacing-1560-px, 60px);
  padding-right: 0px;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
}

.landing-toggle-submit {
  display: flex;
  width: 832px;
  justify-content: space-between;
  align-items: center;
  margin-top: 8px;
}

.landing-toggle-submit-recommendation {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  margin-top: 0px;
  margin-bottom: 0px;
}

.manual-question-inputs {
  display: flex;
  align-items: center;
  width: 100%;
  margin: 0px;
}

.landing-text {
  color: var(--colors-neutral-gray-600, #DBDCDA);
  text-align: center;
  font-family: Inter;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 100%; /* 24px */
  margin-top: 8px;
  margin-bottom: 4px;
}

.landing-text-2 {
  color: var(--red, #880000);
  text-align: center;
  font-family: Inter;
  font-weight: 600;
  font-size: 24px;
  font-style: normal;
  line-height: 100%; /* 24px */
  margin-top: 8px;
  margin-bottom: 24px;
}

.toggle-container span {
  font-size: 14px;
}

.top-nav {
  height: 48px;
}

.active-investigation {
  display: flex;
  flex: 1;
  height: 100%;
  width: 100%;
  flex-direction: column;
}

.investigation-content {
  display: flex;
  flex-direction: column;
  flex: 1;
  width: 100%;
  height: 100%;
  overflow-y: auto; /* Enable scrolling if content overflows */
  margin-bottom: 40px; /* Uncomment to allow chat to go under the input field */
}

.agent-logs-container {
  flex: 1;
  border-left: 2px solid #353E34;
  padding: 16px;
  width: 25%;
}

.recommended-questions-container {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  height: 75%; /* Default height when expanded */
}

.recommended-questions-container.collapsed {
  height: 100px; /* Adjust as needed */
}

.border-click-area {
  height: 20px; /* Clickable area */
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}

.border-click-area:hover .border-line {
  background-color: #5A6657; /* Slightly lighter color */
}

.border-line {
  width: 100%;
  height: 2px; /* Visible border */
  background-color: #353E34;
  position: relative;
}

.arrow-icon {
  position: absolute;
  top: -3px; /* Adjust as needed to center vertically */
  left: 50%;
  transform: translateX(-50%);
  display: inline-block;
  width: 0; 
  height: 0; 
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-top: 8px solid #ffffff;
  transition: transform 0.3s;
}

.arrow-icon.collapsed {
  transform: translateX(-50%) rotate(180deg);
}

.recommended-questions-container-contents {
  display: flex;
  flex-direction: column;
  gap: 4px;
  padding: 16px;
}

.recommendation-text {
  color: var(--colors-neutral-gray-800, #F3F3F3);
  font-size: 14px;
  margin-bottom: 16px;
}

.question-input {
  width: 100%;
  margin: 0px;
}

.chat-container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column-reverse;
  flex-grow: 1;
  overflow-y: auto;
  margin-top: 12px;
}

.chat-messages {
  width: 100%;
  flex: 1;
}

.floating-input-container {
  position: fixed;
  bottom: 0;
  width: 65%; /* Adjust for the left navbar width */
  left: 26%;
  /*background-color: rgba(22, 22, 11, 0.9); */
  padding: 0px;
  z-index: 0;
  margin: 0;
  margin-bottom: 12px;
}


.message-component {
}

/* Add these new styles for the dropdown */
.agent-type-dropdown {
  background-color: var(--colors-neutral-gray-50, #1F291F);
  color: var(--colors-neutral-gray-600, #DBDCDA);
  border: 2px solid var(--colors-neutral-gray-600, #DBDCDA);
  border-radius: 8px;
  padding: 8px;
  margin-left: 4px;
  font-size: 14px;
  font-family: Inter, Helvetica, Arial, sans-serif;
  cursor: pointer;
  height: 45px;
}

.agent-type-dropdown:focus {
  outline: none;
  border-color: var(--colors-green-green-500, #40853E);
}

.landing-toggle-submit-recommendation {
  display: flex;
  align-items: center;
}

.submit-button {
  margin-right: 24px;
}

/*  Scrollbar Styles */

.chat-container::-webkit-scrollbar {
  width: 12px; /* Adjust the width to make it slimmer */
}

.chat-container::-webkit-scrollbar-thumb {
  background-color: #ffffff; /* White scrollbar thumb */
  border-radius: 6px; /* Rounded corners */
}

.chat-container::-webkit-scrollbar-track {
  background-color: #0C160B; /* Background color of the scrollbar track */
  border-radius: 6px; /* Rounded corners */
}
</style>
