<!-- ChatMessage.vue -->
<template>
  <div :class="['chat-message', messageTypeClass]">
    <ProfileAvatar class="avatar" :imageSrc="avatarSrc" />
    <div class="message-content">
      <div class="message-row">

        <!-- Text Box -->
        <div class="text-box">
          <div class="markdown-content" v-html="formattedMessage"></div>

          <pre v-if="isStreaming" class="agent-logs-text" :class="{ faded: isStreaming }">{{ "Raw Agentic Output:" }}</pre>
          <div v-if="isStreaming" ref="agentLogsContainer" class="streaming-agent-logs">
            <div class="inner-streaming-agent-logs">
              <pre class="agent-logs-text" :class="{ faded: isStreaming }">{{ agentLogsWithAnimatedEllipsis }}</pre>
            </div>
          </div>

          <!-- <div v-if="isStreaming" class="loading-spinner-container">
            <LoadingSpinner :isLoading="isStreaming" />
          </div> -->
        </div>

        <!-- Collapsible Component -->
        <CollapsibleComponent ref="collapsible" v-if="type === 'system'">
          <template v-slot:header>
            <span>Details</span>
          </template>

          <!-- Preview Cards for Artifacts -->
          <div class="preview-cards-container" :class="{ faded: isStreaming }">
            <PreviewCard
              v-for="(artifact, index) in artifacts"
              :key="'artifact-' + index"
              :artifact="artifact"
              @open-preview-modal="openPreviewModal"
            />
          </div>
        </CollapsibleComponent>

      </div>

      <div v-if="type === 'system' && !isStreaming" class="system-actions">
        <div class="buttons-group">
          <MiniButton type="ghost" icon="thumbs-up" @click="submitFeedback('thumbs_up')" />
          <MiniButton type="ghost" icon="thumbs-down" @click="submitFeedback('thumbs_down')" />
          <MiniButton type="ghost" icon="comment" @click="openFeedbackPopup" />
        </div>
        <div class="primary-action">
          <PrimaryButton type="secondary" @click="openAgentLogsModal">View Full Output</PrimaryButton>
        </div>
      </div>
    </div>

    <!-- Preview Modal -->
    <div v-if="showPreviewModal" class="modal-overlay" @click.self="closePreviewModal">
      <div class="modal-content">
        <!-- Modal Header -->
        <h3>{{ previewModalToolName }}</h3>
        <!-- Modal Body -->
        <div class="modal-body">
          <div class="modal-header">
            <pre>{{ formattedPreviewToolArgs }}</pre>
          </div>

          <!-- Display Metrics -->
          <div v-if="previewModalArtifact.metrics.length">
            <div
              class="modal-metrics-content"
              v-for="(metric, index) in previewModalArtifact.metrics"
              :key="'metric-' + index"
            >
              <highcharts-chart :options="getMetricChartOptions(metric)" :highcharts="Highcharts" />
            </div>
          </div>

          <!-- Display Images -->
          <!-- <div v-if="previewModalArtifact.images.length">
            <div class="modal-images">
              <img
                v-for="(image, index) in previewModalArtifact.images"
                :key="'image-' + index"
                :src="`data:image/png;base64,${image.image_data}`"
                class="modal-image"
              />
            </div>
          </div> -->

          <!-- Display Dataframes -->
          <div v-if="previewModalArtifact.dataframes.length">
            <div
              class="modal-dataframe-content"
              v-for="(dataframe, index) in previewModalArtifact.dataframes"
              :key="'dataframe-' + index"
            >
              <ag-grid-vue
                class="ag-theme-quartz-dark"
                style="width: 100%; height: 400px;"
                :columnDefs="getColumnDefs(dataframe)"
                :rowData="getRowData(dataframe)"
                :gridOptions="gridOptions"
                @grid-ready="onGridReady"
              ></ag-grid-vue>
            </div>
          </div>

          <!-- Display Texts -->
          <div v-if="previewModalArtifact.texts.length">
            <div class="modal-text-content">
              <pre
                v-for="(textItem, index) in previewModalArtifact.texts"
                :key="'text-' + index"
              >{{ textItem }}
              </pre>
            </div>
          </div>

          <!-- Display Logs -->
          <div v-if="previewModalArtifact.logs.length">
            <div class="collapsible-logs">
              <div class="collapsible-header" @click="toggleLogs">
                <span>Logs</span>
                <span class="collapsible-icon">{{ showLogs ? '-' : '+' }}</span>
              </div>
              <div v-show="showLogs" class="collapsible-content">
                <!-- Logs Content -->
                <div class="logs-container">
                  <div
                    class="log-entry"
                    v-for="(log, index) in previewModalArtifact.logs"
                    :key="'log-' + index"
                  >
                    <!-- Log Title using short_uuid -->
                    <h4 class="log-title">Log ID: {{ log.short_uuid }}</h4>
                    <!-- Log Content -->
                    <vue-json-pretty :data="log" :deep="2" :theme="'dark'" :show-icon="true" :show-line="true" :show-line-number="true"/>
                  </div>
                </div>
              </div>
            </div>
          </div>
          
        </div>

        

        <!-- Modal Footer -->
        <div class="modal-buttons">
          <PrimaryButton type="standard" @click="closePreviewModal">Close</PrimaryButton>
          <PrimaryButton
            v-if="previewModalArtifact.urls.length"
            type="secondary"
            icon="exitarrow"
            @click="viewInPlatform"
          >
            View in Platform
          </PrimaryButton>
        </div>
      </div>
    </div>

    <!-- Agent Logs Modal -->
    <div v-if="showAgentLogsModal" class="modal-overlay" @click.self="closeAgentLogsModal">
      <div class="modal-content">
        <h3>Agentic Tasks and Reasoning</h3>
        <div class="agent-text-box" v-html="formattedAgentLogs"></div>
        <div class="modal-buttons">
          <PrimaryButton type="standard" @click="closeAgentLogsModal">Close</PrimaryButton>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ProfileAvatar from './ProfileAvatar.vue';
import MiniButton from './Buttons/MiniButton.vue';
import PrimaryButton from './Buttons/PrimaryButton.vue';
import CollapsibleComponent from './CollapsibleComponent.vue';
import PreviewCard from './PreviewCard.vue';
// import LoadingSpinner from './LoadSpinner.vue';
import { marked } from 'marked';

import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-quartz.css'; // You can choose a different theme if you prefer
import { AgGridVue } from 'ag-grid-vue3';

import VueJsonPretty from 'vue-json-pretty';
import 'vue-json-pretty/lib/styles.css';
import Highcharts from 'highcharts';
import { Chart } from 'highcharts-vue';
import { getMetricChartOptions } from '@/utils/chartUtils';


marked.setOptions({
  gfm: true,
  breaks: true,
  sanitize: true, // Enables sanitization of the Markdown
});

export default {
  components: {
    ProfileAvatar,
    MiniButton,
    PrimaryButton,
    CollapsibleComponent,
    PreviewCard,
    // LoadingSpinner,
    AgGridVue,
    VueJsonPretty,
    HighchartsChart: Chart,

  },
  props: {
    type: {
      type: String,
      required: true, // 'user' or 'system'
    },
    avatarSrc: {
      type: String,
      default: require('@/assets/logomark.svg'), // Default avatar image
    },
    message: {
      type: String,
      required: true, // The message content to display
    },
    messageId: {
      type: String,
      required: true, // Unique ID for the message
    },
    artifacts: {
      type: Array,
      default: () => [],
    },
    agentLogs: {
      type: String,
      default: '',
    },
    isStreaming: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      showPreviewModal: false,
      previewModalType: '',
      previewModalContent: null,
      previewModalToolName: '',
      previewModalToolArgs: {},
      showAgentLogsModal: false,
      previewModalArtifact: null,
      gridOptions: {
        defaultColDef: {
          sortable: true,
          filter: true,
          resizable: true,
        },
        animateRows: true,
      },
      Highcharts,
      showLogs: false,
      ellipsisIndex: 0,
      ellipsisOptions: ['Loading', 'Loading.', 'Loading..', 'Loading...'],
      ellipsisInterval: null,
    };
  },
  computed: {
    messageTypeClass() {
      return this.type === 'system' ? 'system-message' : 'user-message';
    },
    formattedMessage() {
      const messageWithLineBreaks = this.message.replace(/\n/g, '<br>');
      return marked(messageWithLineBreaks);
    },
    formattedAgentLogs() {
      const logsWithLineBreaks = this.agentLogs.replace(/\n/g, '<br>');
      return marked(logsWithLineBreaks);
    },
    formattedPreviewToolArgs() {
      return JSON.stringify(this.previewModalToolArgs, null, 2);
    },
    agentLogsWithAnimatedEllipsis() {
      return this.agentLogs + "\n" + this.ellipsisOptions[this.ellipsisIndex];
    },
  },
  watch: {
    isStreaming(newVal) {
      if (newVal) {
        this.startEllipsisAnimation();
        this.$refs.collapsible.open();
      } else {
        this.stopEllipsisAnimation();
      }
    },
    showPreviewModal: 'modalVisibilityChanged',
    showAgentLogsModal: 'modalVisibilityChanged',
  },
  methods: {
    submitFeedback(feedbackMessage) {
      this.$emit('submit-message-feedback', this.messageId, feedbackMessage);
    },
    onKeyUp(event) {
      if (event.key === 'Escape' || event.key === 'Esc') {
        if (this.showPreviewModal) {
          this.closePreviewModal();
        }
        if (this.showAgentLogsModal) {
          this.closeAgentLogsModal();
        }
      }
    },
    modalVisibilityChanged() {
      if (this.showPreviewModal || this.showAgentLogsModal) {
        window.addEventListener('keyup', this.onKeyUp);
      } else {
        window.removeEventListener('keyup', this.onKeyUp);
      }
    },
    toggleLogs() {
      this.showLogs = !this.showLogs;
    },
    openFeedbackPopup() {
      const feedbackMessage = prompt('Enter your feedback:');
      if (feedbackMessage) {
        this.$emit('submit-message-feedback', this.messageId, feedbackMessage);
      }
    },
    openPreviewModal(artifact) {
      this.previewModalArtifact = artifact;
      this.previewModalToolName = artifact.tool_name;
      this.previewModalToolArgs = artifact.tool_args;
      this.showPreviewModal = true;
      console.log('Opening Preview Modal for Artifact: ', artifact);
    },
    dataframeRowCount(dataframe) {
      const firstColumn = Object.values(dataframe)[0];
      return Array.from({ length: firstColumn.length }, (_, i) => i);
    },
    closePreviewModal() {
      this.showPreviewModal = false;
      this.previewModalContent = null;
      this.previewModalToolName = '';
      this.previewModalToolArgs = {};
    },
    openAgentLogsModal() {
      this.showAgentLogsModal = true;
    },
    closeAgentLogsModal() {
      this.showAgentLogsModal = false;
    },
    viewInPlatform() {
      // Use the first URL in previewModalArtifact.urls to get the platform URL
      if (this.previewModalArtifact.urls && this.previewModalArtifact.urls.length > 0) {
        const url = this.previewModalArtifact.urls[0];
        window.open(url, '_blank');
      } else {
        alert('Platform URL not available.');
      }
    },
    getColumnDefs(dataframe) {
      return Object.keys(dataframe).map((field) => ({
        headerName: field,
        field: field,
      }));
    },
    getRowData(dataframe) {
      const numRows = Object.values(dataframe)[0].length;
      const rows = [];
      for (let i = 0; i < numRows; i++) {
        const row = {};
        for (const key in dataframe) {
          row[key] = dataframe[key][i];
        }
        rows.push(row);
      }
      return rows;
    },
    onGridReady(params) {
      // Get the grid API
      this.gridApi = params.api;
      this.gridApi.autoSizeAllColumns();
      this.gridApi.sizeColumnsToFit();
    },
    getMetricChartOptions(metricQuery) {
      return getMetricChartOptions(metricQuery);
    },
    startEllipsisAnimation() {
      if (this.ellipsisInterval) return;
      this.ellipsisInterval = setInterval(() => {
        this.ellipsisIndex = (this.ellipsisIndex + 1) % this.ellipsisOptions.length;
      }, 500);
    },
    stopEllipsisAnimation() {
      if (this.ellipsisInterval) {
        clearInterval(this.ellipsisInterval);
        this.ellipsisInterval = null;
        this.ellipsisIndex = 0;
      }
    },

  },
  unmounted() {
    window.removeEventListener('keyup', this.onKeyUp);
    this.stopEllipsisAnimation();
  },
};
</script>

<style scoped>
.chat-message {
  display: flex;
  width: 75%;
  padding: 0px var(--spacing-520-px, 20px);
  align-items: flex-start;
  gap: 12px;
  border-radius: 12px;
  margin-bottom: 12px;
  margin-left: auto;
  margin-right: auto;
}

.user-message {
  background: var(--colors-neutral-gray-50, #1F291F);
}

.loading-spinner-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 16px;
}

.text-box {
  flex: 1;
  max-width: calc(100%);
  overflow-wrap: break-word;
  margin-top: 22px;
}

.text-box .loading-spinner-container {
  margin-top: 0;
}

/* Replace your existing selector with this */
.markdown-content ::v-deep a {
  color: var(--colors-primary-green, #40853E) !important;
  text-decoration: none;
}

/* Also, update the visited, hover, and active states */
.markdown-content ::v-deep a:visited {
  color: var(--colors-primary-green, #669D65) !important;
}

.markdown-content ::v-deep a:hover {
  color: var(--colors-primary-green-dark, #669D65) !important;
}

.agent-text-box ::v-deep a {
  color: var(--colors-primary-green, #40853E) !important;
  text-decoration: none;
}

/* Also, update the visited, hover, and active states */
.agent-text-box ::v-deep a:visited {
  color: var(--colors-primary-green, #669D65) !important;
}

.agent-text-box ::v-deep a:hover {
  color: var(--colors-primary-green-dark, #669D65) !important;
}


.streaming-agent-logs {
  max-height: 200px;
  overflow-y: scroll;
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
  display: flex;
  flex-direction: column-reverse;
  flex-grow: 1;
}

.streaming-agent-logs::-webkit-scrollbar {
  display: none; /* Chrome, Safari, Opera */
}

.inner-streaming-agent-logs {
  flex: 1;
  widht: 100%;
}

.agent-logs-text {
  color: var(--colors-neutral-gray-500, #C2C5C2);
  font-family: 'Roboto Mono';
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 166.667% */
  white-space: pre-wrap;
}

.faded {
  opacity: 0.4;
}





.user-message .text-box {
  color: var(--colors-neutral-gray-600, #DBDCDA);
  font-family: Inter, sans-serif;
  font-size: 14px;
  font-weight: 400;
  font-style: normal;
  line-height: 20px;
  align-self: stretch;
  margin-bottom: 12px;
  margin-right: 0px;
  margin-left: 0px;
}

.system-message .text-box {
  color: var(--colors-white, #FFF);
  font-family: Inter, sans-serif;
  font-size: 14px;
  font-weight: 400;
  font-style: normal;
  line-height: 20px;
  align-self: stretch;
  margin-bottom: 12px;
  margin-right: 0px;
  margin-left: 0px;
}

.message-content {
  width: 100%;
}

.message-row {
  display: flex;
  flex-direction: row;
  align-items: stretch;
  gap: 10px; /* Adds margin between text box and collapsible component */
  margin-bottom: 12px;
}

.system-actions {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  align-self: stretch;
  margin-top: 0px;
  margin-right: 0px;
  margin-left: 0px;
  margin-bottom: 20px;
  width: 100%;
}

.buttons-group {
  display: flex;
  gap: 0px;
}

.primary-action {
  margin-left: auto;
  margin-right: 0px;
}

.avatar {
  margin-left: 20px;
  margin-top: 16px;
  margin-bottom: 16px;
}

.preview-cards-container {
  display: flex;
  flex-wrap: wrap;
  margin-top: 8px;
  margin-bottom: 0px;
  /* max height should be equivalent to existing heigh from textbox and other components */
  overflow-y: auto; /* Enable vertical scrolling */
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-content {
  background-color: var(--colors-black, #0C160B);
  padding: 20px;
  border-radius: 16px;
  width: 50%;
  max-height: 80%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  border: 1px solid var(--colors-neutral-gray-25-alpha, rgba(255, 255, 255, 0.04));
  box-shadow: 0px 4px 40px 0px #000;
}

.modal-header {
  /* Fixed header */
  background-color : var(--colors-green-green-600, #161F15);
  border-radius: 12px;
  display: flex;
  padding: 12px 16px;
  align-items: flex-start;
  gap: 4px;
  align-self: stretch;
  margin-bottom: 20px;
}

.modal-header h3,
.modal-header pre {
  color: var(--colors-green-green-600, #DBDCDA);
  font-family: 'Roboto Mono', monospace;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  margin: 0;
}

.modal-content h3 {
  color: var(--colors-white, #FFF);
  margin-bottom: 10px;
  font-family: Inter, sans-serif;
  font-size: 16px;
}

.modal-header h3 {
  margin-bottom: 10px;
}

.modal-body {
  flex: 1;
  overflow-y: auto;
  padding-top: 10px;
}

.modal-images {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.modal-image {
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
  margin-bottom: 20px;
  border-radius: 8px;
}

.modal-text-content pre {
  color: var(--colors-green-green-600, #FFF);
  font-family: 'Roboto Mono', monospace;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  white-space: pre-wrap; /* Ensure long lines wrap */
}

.agent-text-box pre,
.modal-content pre {
  white-space: pre-wrap;
}

.agent-text-box {
  color: var(--colors-white, #FFF);
  font-family: Inter, sans-serif;
  font-size: 10px !important;
  font-weight: 400 !important;
  line-height: 20px !important;
  margin-top: 20px !important;
  background-color : var(--colors-green-green-600, #161F15);
  border-radius: 12px;
  display: flex;
  padding: 12px 16px;
  align-items: flex-start;
  gap: 4px;
  align-self: stretch;
  margin-bottom: 20px;
  overflow-y: auto;
  max-height: 80%;
}

.modal-dataframe-content {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
  margin-left: 10px;
  margin-right: 10px;
}

.ag-theme-quartz-dark {
  width: 100%;
  height: 400px;
  --ag-background-color: var(--colors-neutral-gray-800, #0C160B);
  --ag-foreground-color: var(--colors-white, #FFF);
  --ag-header-background-color: var(--colors-neutral-gray-700, #2A2F28);
}

/* Adjust AG Grid fonts and colors to match your theme */
.ag-theme-quartz-dark .ag-header-cell-label .ag-header-cell-text {
  color: var(--colors-white, #FFF);
  font-family: 'Roboto Mono', monospace;
}

.ag-theme-quartz-dark .ag-cell {
  color: var(--colors-white, #FFF);
  font-family: 'Roboto Mono', monospace;
}


.dataframe-table {
  width: auto;
  border-collapse: collapse;
  color: var(--colors-white, #FFF);
  font-family: 'Roboto Mono', monospace;
  margin-left: 10px;
  margin-right: 10px;
}

.dataframe-table th,
.dataframe-table td {
  border: 1px solid var(--colors-neutral-gray-600, #DBDCDA);
  padding: 4px;
  text-align: left;
}

.dataframe-table th {
  background-color: var(--colors-neutral-gray-700, #2A2F28);
}

.dataframe-table td {
  background-color: var(--colors-neutral-gray-800, #0C160B);
}

.modal-buttons {
  /* Fixed footer */
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
  gap: 20px;
}

.modal-buttons .primary-button {
  width: 150px;
}

.modal-logs-content {
  max-height: 400px;
  overflow-y: auto;
  background-color: #1e1e1e;
  padding: 10px;
  border-radius: 4px;
}

.modal-metrics-content {
  margin-top: 20px;
  margin-bottom: 20px;
}

/* Collapsible Logs Styles */
.collapsible-logs {
  margin-top: 20px;
  border-radius: 8px;
}

.collapsible-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #161F15;
  padding: 12px 16px;
  cursor: pointer;
  color: var(--colors-neutral-gray-600, #DBDCDA);
  font-size: 16px;
  font-weight: 500;
  border-radius: 8px;
}

.collapsible-icon {
  font-size: 18px;
}

.collapsible-content {
  padding: 10px 16px;
  background-color: #1e1e1e;
  max-height: 400px;
  overflow-y: auto;
  border-top: 1px solid var(--colors-neutral-gray-25-alpha, rgba(255, 255, 255, 0.04));
}

.logs-container {
  padding: 10px 0;
}

.log-entry {
  margin-bottom: 20px;
}

.log-title {
  font-size: 16px;
  margin-bottom: 5px;
  color: var(--colors-neutral-gray-600, #DBDCDA);
}

/* Adjust button alignment */
.modal-buttons .primary-button:first-child {
  margin-right: auto;
}

.modal-buttons .primary-button:last-child {
  margin-left: auto;
}



/* Style scrollbar */
.modal-body::-webkit-scrollbar {
  width: 8px;
}
.modal-body::-webkit-scrollbar-thumb {
  background-color: var(--colors-neutral-gray-600, #DBDCDA);
  border-radius: 4px;
}
.modal-body::-webkit-scrollbar-track {
  background-color: var(--colors-neutral-gray-800, #0C160B);
}

</style>
