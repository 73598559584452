// src/utils/chartUtils.js

export function getMetricChartOptions(metricQuery, isPreview = false) {
    // Define a set of colors to use for different series
    const colors = [
      {
        line: 'rgba(238, 199, 41, 1)', // Yellow
        fillStart: 'rgba(238, 199, 41, 0.20)',
        fillEnd: 'rgba(238, 199, 41, 0.00)',
      },
      {
        line: 'rgba(237, 125, 49, 1)', // Orange
        fillStart: 'rgba(237, 125, 49, 0.20)',
        fillEnd: 'rgba(237, 125, 49, 0.00)',
      },
      {
        line: 'rgba(91, 155, 213, 1)', // Blue
        fillStart: 'rgba(91, 155, 213, 0.20)',
        fillEnd: 'rgba(91, 155, 213, 0.00)',
      },
      {
        line: 'rgba(112, 173, 71, 1)', // Green
        fillStart: 'rgba(112, 173, 71, 0.20)',
        fillEnd: 'rgba(112, 173, 71, 0.00)',
      },
      {
        line: 'rgba(165, 165, 165, 1)', // Gray
        fillStart: 'rgba(165, 165, 165, 0.20)',
        fillEnd: 'rgba(165, 165, 165, 0.00)',
      },
      // Add more colors if needed
    ];
  
    // Common options
    const chartOptions = {
      chart: {
        type: 'area',
        backgroundColor: null, // Set to null for transparency
        borderRadius: 12,
        spacing: isPreview ? [5, 5, 5, 5] : [10, 10, 15, 10],
        width: isPreview ? null : null, // Width will be controlled by the container
        height: isPreview ? 145 : null, // Adjust height as needed
      },
      title: {
        text: metricQuery.query || 'Metric',
        style: {
          color: 'var(--colors-neutral-gray-600, #DBDCDA)',
          fontSize: isPreview ? '10px' : '14px',
        },
      },
      xAxis: {
        type: 'datetime',
        lineColor:
          'var(--colors-neutral-gray-50-alpha, rgba(255, 255, 255, 0.08))',
        tickColor:
          'var(--colors-neutral-gray-50-alpha, rgba(255, 255, 255, 0.08))',
        labels: {
          style: {
            color: 'var(--colors-neutral-gray-600, #DBDCDA)',
            fontSize: isPreview ? '8px' : '12px',
          },
        },
        title: {
          text: '',
        },
      },
      yAxis: {
        title: {
          text: '',
        },
        gridLineColor:
          'var(--colors-neutral-gray-50-alpha, rgba(255, 255, 255, 0.08))',
        labels: {
          style: {
            color: 'var(--colors-neutral-gray-600, #DBDCDA)',
            fontSize: isPreview ? '8px' : '12px',
          },
        },
      },
      series: [],
      plotOptions: {
        area: {
          marker: {
            enabled: false,
          },
        },
        series: {
          shadow: false,
        },
      },
      tooltip: {
        enabled: !isPreview, // Disable tooltips in preview
      },
      legend: {
        enabled: true, // Enable legend since we have multiple series
        itemStyle: {
          color: 'var(--colors-neutral-gray-600, #DBDCDA)',
          fontSize: isPreview ? '8px' : '12px',
        },
      },
      credits: {
        enabled: false,
      },
    };
  
    // Loop over each metric and add it to the series
    console.log("Metric query is: ", metricQuery); 
    metricQuery.metrics.forEach((metric, index) => {
      const data = Object.entries(metric.values).map(
        ([datetimeStr, value]) => {
          const timestamp = new Date(datetimeStr).getTime();
          return [timestamp, value];
        }
      );
  
      data.sort((a, b) => a[0] - b[0]);
  
      const colorIndex = index % colors.length;
      const color = colors[colorIndex];
  
      let series_name = metric.name || `Series ${index + 1}`;
      if (metric.metadata) {
        const key = Object.keys(metric.metadata)[0];
        if (key) {
            series_name = metric.metadata[key];
        }
      }

      chartOptions.series.push({
        name: series_name,
        data,
        color: color.line,
        lineWidth: isPreview ? 1 : 2,
        fillColor: {
          linearGradient: { x1: 0, y1: 0, x2: 0, y2: 1 },
          stops: [
            [0, color.fillStart],
            [1, color.fillEnd],
          ],
        },
      });
              
    });
  
    return chartOptions;
  }
  