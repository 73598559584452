<!-- CollapsibleComponent.vue -->
<template>
  <div :class="['collapsible-container', { 'collapsed': isCollapsed }]">
    <div class="collapsible-header">
      <PrimaryButton
        class="button-icon"
        type="ghost"
        icon="carrot"
        iconPosition="right"
        :class="{ 'collapsed-button': isCollapsed }"
        @click="toggleCollapse"

      >
        <slot name="header"></slot>
      </PrimaryButton>
    </div>
    <transition name="collapse">
      <div v-show="!isCollapsed" class="collapsible-content">
        <slot></slot>
      </div>
    </transition>
  </div>
</template>


<script>
import PrimaryButton from './Buttons/PrimaryButton.vue';

export default {
  name: 'CollapsibleComponent',
  components: { PrimaryButton },
  data() {
    return {
      isCollapsed: false, // Default to expanded
    };
  },
  methods: {
    toggleCollapse() {
      this.isCollapsed = !this.isCollapsed;
    },
    open() {
      this.isCollapsed = false;
    },
  },
};
</script>



<style scoped>
.collapsible-container {
  width: 350px; /* Fixed width */
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
}

.collapsible-header {
  display: flex;
  justify-content: flex-end; /* Aligns the button to the right */
}

.collapsible-content {
  min-height: 300px;
  max-height: 700px;
  overflow-y: auto;
}

/* Remove width settings from collapsed and expanded states */
.collapsible-container.collapsed {
    width: 0;
}

/* color scrollbar background */
.collapsible-content::-webkit-scrollbar {
  background-color: var(--colors-neutral-gray-25, #0C160B);
  width: 0px;
}

.collapsible-content::-webkit-scrollbar-thumb {
  background-color: var(--colors-neutral-gray-50, #0C160B);
  width: 0px;
}

</style>

