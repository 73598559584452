import { createApp } from 'vue'
import App from './App.vue'
import './styles.css';
import { createAuth0 } from '@auth0/auth0-vue';

// createApp(App).mount('#app')
const app = createApp(App);

app.use(
    createAuth0({
      domain: "dev-ppocc0m78uclwopp.us.auth0.com",
      clientId: "dn0MZXNPvX59SkMi3KK6vnikyqRewzJP",
      authorizationParams: {
        redirect_uri: window.location.origin,
        audience: "https://api.traversal.com/",
      },
      cacheLocation: 'localstorage', // Persist session in local storage
    })
    
  );
  
  app.mount('#app');
  