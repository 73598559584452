<template>
  <div class="preview-card" @click="handleClick">
    <div class="tool-info">
      <span class="tool-name">{{ artifact.tool_name }}</span>
    </div>
    <div class="preview-content">
      <!-- Metric Preview -->
      <div v-if="thumbnailType === 'metric'" class="preview-metric">
        <highcharts-chart
          :options="metricChartOptions"
          :highcharts="Highcharts"
          style="width: 100%; height: 100%;"
        />
      </div>
      <!-- Image Preview -->
      <img v-else-if="thumbnailType === 'image'" :src="thumbnailContent" class="preview-image" />
      <!-- URL Preview -->
      <div v-else-if="thumbnailType === 'url'" class="preview-url">
        <img src="@/assets/link_icon.svg" class="link-icon" />
      </div>
      <!-- Text Preview -->
      <div v-else-if="thumbnailType === 'text'" class="preview-text">
        <pre class="text-preview-content">{{ truncatedText }}</pre>
      </div>
      <!-- Dataframe Preview -->
      <div v-else-if="thumbnailType === 'dataframe'" class="preview-dataframe">
        <table class="dataframe-preview-table">
          <thead>
            <tr>
              <th v-for="(values, columnName) in thumbnailContent" :key="columnName">{{ columnName }}</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="rowIndex in previewRowCount" :key="rowIndex">
              <td v-for="(values, columnName) in thumbnailContent" :key="columnName">
                {{ values[rowIndex] }}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>


<script>
import Highcharts from 'highcharts';
import { Chart } from 'highcharts-vue';
import { getMetricChartOptions } from '@/utils/chartUtils';

export default {
  name: 'PreviewCard',
  components: {
    HighchartsChart: Chart,
  },
  props: {
    artifact: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      Highcharts,
    };
  },
  computed: {
      truncatedText() {
        const maxLength = 200; // Adjust as needed
        const textContent = this.thumbnailContent;
        return textContent.length > maxLength ? textContent.substring(0, maxLength) + '...' : textContent;
      },
      previewRowCount() {
        if (this.thumbnailType === 'dataframe' && this.thumbnailContent) {
          const firstColumn = Object.values(this.thumbnailContent)[0];
          return Array.from({ length: Math.min(firstColumn.length, 5) }, (_, i) => i); // Limit to 5 rows
        }
        return [];
      },
      thumbnailType() {
        if (this.artifact.metrics && this.artifact.metrics.length > 0) {
          return 'metric';
        } else if (this.artifact.images && this.artifact.images.length > 0) {
          return 'image';
        } else if (this.artifact.dataframes && this.artifact.dataframes.length > 0) {
          return 'dataframe';
        } else if (this.artifact.texts && this.artifact.texts.length > 0) {
          return 'text';
        } else if (this.artifact.urls && this.artifact.urls.length > 0) {
          return 'url';
        }
        return 'unknown';
      },
      thumbnailContent() {
        switch (this.thumbnailType) {
          case 'image':
            return `data:image/png;base64,${this.artifact.images[0].image_data}`;
          case 'dataframe':
            return this.artifact.dataframes[0];
          case 'text':
            // join texts with new line
            return this.artifact.texts.join('\n');
          case 'url':
            return this.artifact.urls[0];
          default:
            return null;
        }
      },
      metricChartOptions() {
        if (this.thumbnailType === 'metric') {
          // For simplicity, we'll use the first metric
          const metricQuery = this.artifact.metrics[0];
          return getMetricChartOptions(metricQuery, true); // Pass true for preview
        }
        return null;
      },
  },
  methods: {
    handleClick() {
      // Emit the entire artifact
      this.$emit('open-preview-modal', this.artifact);
    },
  },
};
</script>

<style scoped>
.preview-card {
  width: 350px;
  height: 150px;
  border-radius: 8px;
  overflow: hidden;
  margin: 8px;
  cursor: pointer;
  background-color: var(--colors-neutral-gray-50, #161F15);
  display: flex;
  flex-direction: column;
  align-items: center;
  border: 1px solid var(--colors-neutral-gray-600, #DBDCDA);
}

/* Add this CSS rule within your <style scoped> tag */
.preview-card:hover .tool-info {
  background: var(--colors-green-green-100-alpha, rgba(64, 133, 62, 0.35));
}

.preview-card:hover .preview-content {
  background: var(--colors-neutral-gray-25-alpha, rgba(255, 255, 255, 0.04));

}


.preview-content {
  flex: 1;
  width: 100%;
  height: 70%;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}

.preview-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

/* Metric Preview */
.preview-metric {
  width: 100%;
  height: 100%;
}


.preview-url {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: var(--colors-white, #FFF);
  text-align: center;
  padding: 8px;
}

.preview-text {
  padding: 8px;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.text-preview-content {
  margin-top: 8px;
  font-size: 10px;
  line-height: 12px;
  color: var(--colors-white, #FFF);
  overflow: hidden;
  text-overflow: ellipsis;
}

.preview-dataframe {
  width: 100%;
  overflow: hidden;
}

.dataframe-preview-table {
  width: 100%;
  font-size: 10px;
  color: var(--colors-white, #FFF);
  border-collapse: collapse;
}

.dataframe-preview-table th,
.dataframe-preview-table td {
  border: 1px solid var(--colors-neutral-gray-600, #DBDCDA);
  padding: 2px;
  text-align: left;
}

.tool-info {
  width: 100%;
  padding: 4px;
  background-color: #162C15;
  text-align: left;
  padding-left: 16px;
}

.tool-name {
  color: var(--colors-green-green-600, #669D65);
  /* text-base/semibold */
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px; /* 142.857% */
}

</style>
